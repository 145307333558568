var filterBTN = document.querySelector(".form__filters");
var filterBlock = document.querySelector(".form__filtersBox");

filterBTN.addEventListener("click", function () {
    filterBlock.classList.toggle("form__filters__open");
});

document
    .querySelectorAll(".form__filtersBox__title__result input")
    .forEach(function (item) {
        item.addEventListener("focus", function () {
            if (item.value <= 0) {
                item.value = "";
            }
        });
        item.addEventListener("blur", function () {
            if (item.value == "" || item.value <= 0) {
                item.value = 0;
            }
        });
    });
